import React, { useState, useEffect } from 'react';

import { Typography} from '@mui/material';

import TopBar from './TopBar';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  pageTitleCont: {
    '& button': {
      marginLeft: '0.5em',
    },
    '& .titleTooltip': {
      fontSize: '1.5em',
      "@media print": {
        display: 'none',
      },
    },
    "@media print": {
      '& h5': {
        fontSize: '12px',
      },
      display: (props) => {
        return (props.hideOnPrint === true) ? 'none': 'unset'
      }
    },

  }
}));

export const PageTitle = ({thepage, suffix, hideHelpButton}) => {

  const styleProps = { hideOnPrint: !hideHelpButton };
  const classes = useStyles(styleProps);

  const [pageTitle, setPageTitle] = useState(null);
  const [helpText, setHelpText] = useState(null);

  useEffect(() => {
    if ( thepage === '/dashboard' ) {
      setPageTitle('Dashboard');
      // setHelpText(<React.Fragment>All booked and not booked appointment calls from marketing sources, not to or from regular practice phone number.</React.Fragment>);
    } else if ( thepage === '/calls/all' ) {
      setPageTitle('All Calls');
      setHelpText(<React.Fragment>All Calls detected and recognized by RingAI</React.Fragment>);
    } else if ( thepage === '/calls/answered' ) {
      setPageTitle('Appointment Calls');
      setHelpText(<React.Fragment>All booked and not booked appointment calls from marketing sources, not to or from regular practice phone number.</React.Fragment>);
    }  else if ( thepage === '/calls/booked' ) {
      setPageTitle('Appointments Booked');
      setHelpText(<React.Fragment>Confirmed revenue generating exam appointment calls, <b>not</b> reschedules, adjustments, pick-ups, etc.</React.Fragment>);
    } else if ( thepage === '/calls/notbooked' ) {
      setPageTitle('Appointments NOT Booked');
      setHelpText(<React.Fragment>Calls about a revenue generating appointment, including time, cost, insurance, etc, that did not result in a booked appointment.  Includes cancellations.</React.Fragment>);
    } else if ( thepage === '/calls/other' ) {
      setPageTitle('Service Calls');
      setHelpText(<React.Fragment>Service calls, includes customer service, rescheduling, voicemails, etc.</React.Fragment>);
    } else if ( thepage === '/calls/busy' ) {
      setPageTitle('Busy Signals');
      setHelpText(<React.Fragment>Busy signals or technical issue</React.Fragment>);
    } else if ( thepage === '/calls/noanswer' ) {
      setPageTitle('No Answer');
      setHelpText(<React.Fragment>No answer calls with length greater 30 seconds</React.Fragment>);
    } else if ( thepage === '/calls/isspam' ) {
      setPageTitle('Spam Calls');
      setHelpText(<React.Fragment>No answer calls with length less than 30 seconds</React.Fragment>);
    } else if ( thepage === '/calls/unknown' ) {
      setPageTitle('Unknown Calls');
      setHelpText(<React.Fragment>Calls which did not match any of classifying rules</React.Fragment>);
    } else if ( thepage === '/calls/hangup' ) {
      setPageTitle('Hangups');
      setHelpText(<React.Fragment>IVR/VM answered & Caller Hang up, include zero duration calls</React.Fragment>);
    } else if ( thepage === '/calls/voicemail' ) {
      setPageTitle('Voicemails');
      setHelpText(<React.Fragment>Caller left the voicemail</React.Fragment>);
    } else if ( thepage === '/calls/missed' ) {
      setPageTitle('Calls Missed');
      setHelpText(<React.Fragment>Calls not answered, includes busy signals and ringing 6+ times. Does <b>not</b> include voicemails.</React.Fragment>);
    } else if ( thepage === '/calls/spam' ) {
      setPageTitle('Spam Calls or Caller Hang Up');
      setHelpText(<React.Fragment>Caller hung up before 6 rings.  Usually spam callers that hang up when hearing that the call will be recorded.</React.Fragment>);
    } else if ( thepage === '/reports/callviews' ) {
      setPageTitle('Call Views Last 28 Days');
      setHelpText(<React.Fragment>Calls that have been reviewed by managers, may have tags and notes attached.</React.Fragment>);
    } else if ( thepage === '/data' ) {
      setPageTitle('Complete List of Locations');
      setHelpText(<React.Fragment>Overview of phone call activity across all practices.</React.Fragment>);
    } else if ( thepage === '/account/settings' ) {
      setPageTitle('Settings');
    } else if ( thepage === '/manage/groups' ) {
      setPageTitle('Practices / Groups');
    } else if ( thepage === '/manage/offices' ) {
      setPageTitle('Locations');
    } else if ( thepage === '/manage/users' ) {
      setPageTitle('Users');
    }
  }, [thepage]);

  if ( hideHelpButton ) {
    // used on calls logs
    return (
      <Typography variant="h5">{pageTitle}{(suffix) ? suffix : ''}</Typography>
    );
  } else {
    // used as page titles
    return (
      <TopBar className={classes.pageTitleCont}  pageTitle={pageTitle} helpText={helpText} />
    );
  }

};

export default PageTitle;
