import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, List, ListItem, ListSubheader, ListItemIcon, ListItemText, Divider, Collapse } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import GroupIcon from '@mui/icons-material/Group';
import ApartmentIcon from '@mui/icons-material/Apartment';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import VisibilityIcon from '@mui/icons-material/Visibility';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import BugReportIcon from '@mui/icons-material/BugReport';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import SettingsPhoneIcon from '@mui/icons-material/SettingsPhone';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import DownloadIcon from '@mui/icons-material/Download';
import StyledNavLink from '../Common/StyledNavLink';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  menuContainer: {
    '& .nestedList': {
      '& .MuiListItemIcon-root': {
        marginLeft: '0.5em',
        marginRight: '-0.5em',
        opacity: '0.5',
        minWidth: '3em',
      },
    },
    '& .menuIcon': {
      minWidth: '3em',
    },
  },
}));

const AdminMenu = () => {
  const classes = useStyles();
  const location = useLocation();

  const [isAnsweredOpen, setIsAnsweredOpen] = useState(false);
  const [isMissedCallsOpen, setIsMissedCallsOpen] = useState(false);

  useEffect(() => {
    setIsAnsweredOpen(location.pathname.startsWith('/calls/answered')
      || location.pathname.startsWith('/calls/booked')
      || location.pathname.startsWith('/calls/notbooked')
      || location.pathname.startsWith('/calls/other'));

    setIsMissedCallsOpen(location.pathname.startsWith('/calls/busy')
      || location.pathname.startsWith('/calls/missed')
      || location.pathname.startsWith('/calls/noanswer')
      || location.pathname.startsWith('/calls/hangup')
      || location.pathname.startsWith('/calls/voicemail'));
  }, [location]);

  return (
    <Box className={classes.menuContainer}>
      <List>
        <ListItem button component={StyledNavLink} to="/dashboard" className="menuLink">
          <ListItemIcon className="menuIcon"><HomeIcon /></ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
        <Divider />

        <ListItem button component={StyledNavLink} to="/calls/all">
          <ListItemIcon className="menuIcon"><PhoneInTalkIcon /></ListItemIcon>
          <ListItemText primary="All Calls" />
        </ListItem>

        <ListItem button component={StyledNavLink} to="/calls/answered">
          <ListItemIcon className="menuIcon"><AttachMoneyIcon /></ListItemIcon>
          <ListItemText primary="Appointment Calls" />
        </ListItem>
        <Collapse in={isAnsweredOpen}>
          <List disablePadding dense className="nestedList">
            <ListItem button component={StyledNavLink} to="/calls/booked">
              <ListItemIcon><SubdirectoryArrowRightIcon /></ListItemIcon>
              <ListItemText primary="$ Booked" />
            </ListItem>
            <ListItem button component={StyledNavLink} to="/calls/notbooked">
              <ListItemIcon><SubdirectoryArrowRightIcon /></ListItemIcon>
              <ListItemText primary="$ Not Booked" />
            </ListItem>
          </List>
        </Collapse>

        <ListItem button component={StyledNavLink} to="/calls/other">
          <ListItemIcon className="menuIcon"><SettingsPhoneIcon /></ListItemIcon>
          <ListItemText primary="Service Calls" />
        </ListItem>

        <ListItem button component={StyledNavLink} to="/calls/missed">
          <ListItemIcon className="menuIcon"><PhoneMissedIcon /></ListItemIcon>
          <ListItemText primary="Missed Calls" />
        </ListItem>
        <Collapse in={isMissedCallsOpen}>
          <List disablePadding dense className="nestedList">
            <ListItem button component={StyledNavLink} to="/calls/busy">
              <ListItemIcon><SubdirectoryArrowRightIcon /></ListItemIcon>
              <ListItemText primary="Busy Signal" />
            </ListItem>
            <ListItem button component={StyledNavLink} to="/calls/noanswer">
              <ListItemIcon><SubdirectoryArrowRightIcon /></ListItemIcon>
              <ListItemText primary="No Answer" />
            </ListItem>
            <ListItem button component={StyledNavLink} to="/calls/hangup">
              <ListItemIcon><SubdirectoryArrowRightIcon /></ListItemIcon>
              <ListItemText primary="Hang Up" />
            </ListItem>
            <ListItem button component={StyledNavLink} to="/calls/voicemail">
              <ListItemIcon><SubdirectoryArrowRightIcon /></ListItemIcon>
              <ListItemText primary="Voicemail" />
            </ListItem>
          </List>
        </Collapse>

        <ListItem button component={StyledNavLink} to="/calls/isspam">
          <ListItemIcon className="menuIcon"><BugReportIcon /></ListItemIcon>
          <ListItemText primary="Spam Calls" />
        </ListItem>
      </List>

      <Divider />

      <List>
        <ListItem button component={StyledNavLink} to="/calls/tagged">
          <ListItemIcon className="menuIcon"><LocalOfferIcon /></ListItemIcon>
          <ListItemText primary="Tagged Calls" />
        </ListItem>

        <ListItem button component={StyledNavLink} to="/calls/unknown">
          <ListItemIcon className="menuIcon"><ContactSupportIcon /></ListItemIcon>
          <ListItemText primary="Unknown Calls" />
        </ListItem>
        <ListItem button component={StyledNavLink} to="/reports/callviews">
          <ListItemIcon className="menuIcon"><VisibilityIcon /></ListItemIcon>
          <ListItemText primary="Call Views" />
        </ListItem>
        <ListItem button component={StyledNavLink} to="/data">
          <ListItemIcon className="menuIcon"><DownloadIcon /></ListItemIcon>
          <ListItemText primary="Data Download" />
        </ListItem>
      </List>

      <Divider />

      <List>
        <ListSubheader disableSticky>Manage</ListSubheader>

        <ListItem button component={StyledNavLink} to="/manage/groups">
          <ListItemIcon className="menuIcon"><GroupIcon /></ListItemIcon>
          <ListItemText primary="Practices / Groups" />
        </ListItem>
        <ListItem button component={StyledNavLink} to="/manage/offices">
          <ListItemIcon className="menuIcon"><ApartmentIcon /></ListItemIcon>
          <ListItemText primary="Locations" />
        </ListItem>
        <ListItem button component={StyledNavLink} to="/manage/users">
          <ListItemIcon className="menuIcon"><PermContactCalendarIcon /></ListItemIcon>
          <ListItemText primary="Users" />
        </ListItem>
      </List>
    </Box>
  );
}

export default AdminMenu;
