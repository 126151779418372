import React, {useState, useEffect} from 'react';
import { useSelector } from "react-redux";

import { Card, CardHeader, IconButton, Skeleton, CardContent, List, ListItem, ListItemButton, ListItemText, TextareaAutosize, CardActions, Typography } from '@mui/material';

// import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SendIcon from '@mui/icons-material/Send';

import moment from 'moment-timezone';

import useDropdownOffices from "../../hooks/useDropdownOffices";

import makeStyles from '@mui/styles/makeStyles';
import mongoConfig from "../../mongo";

const useStyles = makeStyles((theme) => ({
  notesCard: {
    boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
    backgroundColor: theme.palette.chat.background,
    position: 'fixed',
    left: '1em',
    bottom: '1em',
    maxWidth: '25em',
    width: '100%',
    zIndex: 9999,
    '& .MuiCardHeader-root': {
      backgroundColor: theme.palette.chat.heading,
      color: theme.palette.chat.headingText,
      '& .MuiTypography-root': {
        fontWeight: 500,
        fontSize: '0.875rem',
        lineHeight: '1.75',
        letterSpacing: '0.02857em',
        textTransform: 'uppercase',
      },
      '& .MuiIconButton-root': {
        color: theme.palette.chat.headingText,
      },
      '& .MuiCardHeader-avatar button': {
        marginTop: '-4px',
        marginBottom: '-4px',
        marginLeft: '-8px',
      }
    },
  },
  officesList: {
    maxHeight: '28em',
    overflow: 'auto',
  },
  notesList: {
    maxHeight: '28em',
    overflow: 'auto',
    fontSize: '0.8rem',
    '& .MuiListItem-root': {
      '& .MuiListItemText-primary': {
        backgroundColor: theme.palette.chat.noteBubbleBg,
        borderRadius: '0.5em',
        padding: '0.25em 0.5em',
      },
      '& .MuiListItemText-secondary': {
        textAlign: 'right',
        padding: '0 0.5em',
        fontSize: 'smaller',
      },
      marginBottom: '0.5em'
    }
  },
  notesActions: {
    '& textarea': {
      padding: '8.5px 14px',
      width: '100%',
      fontWeight: 400,
      fontSize: '0.8rem',
      lineHeight: '1.4375em',
      letterSpacing: '0.00938em',
      borderRadius: '4px',
    }
  }
}));

export const AdminNotesCard = ({closeChat}) => {
  const mongoUser = useSelector( (state) => state.auth.user );
  const userTimeZone = ( !!mongoUser.customData.timeZone && mongoUser.customData.timeZone !== '' ) ? mongoUser.customData.timeZone : moment.tz.guess();

  const classes = useStyles();

  const filterSelectedOfficeID = useSelector( (state) => { (state.app.filterOptions.selectedOffice) ? state.app.filterOptions.selectedOffice.id : null} );

  const officesToDisplay = useDropdownOffices('all', null) || [];
  // console.log(officesToDisplay);

  const [selectedOfficeID, setSelectedOfficeID] = useState(null); // list when null || chat when selected
  const [foundOfficeInfo, setFoundOfficeInfo] = useState(null); // list when null || chat when selected
  // const [officeManagersRelated, setOfficeManagersRelated] = useState([]);

  const [newNoteText, setNewNoteText] = useState('');
  const [officeNotes, setOfficeNotes] = useState(undefined);

  const [saveInProgress, setSaveInProgress] = useState(false);



  const handleSendNoteBtn = () => {
    if ( newNoteText === '' ) return;

    setSaveInProgress(true);

    const noteRecord = {
      utcDateTime: new Date(),
      company: foundOfficeInfo.company,
      officeID: foundOfficeInfo._id,
      officeName: foundOfficeInfo.name,
      byUserID: mongoUser.customData.userID,
      byUserName: mongoUser.customData.displayName,
      readByUsers: [],
      note: newNoteText
    };

    mongoUser
      .mongoClient(mongoConfig.realmServiceName)
      .db(mongoConfig.databaseName)
      .collection('notes')
      .insertOne(noteRecord)
      .then( (created) => {
        noteRecord['_id'] = created.insertedId;
        setNewNoteText(''); // reset text in input
        setOfficeNotes([noteRecord, ...officeNotes]);
        setSaveInProgress(false);
      }).catch((err) => {
        console.log('Notes update error: ' + err);
        setSaveInProgress(false);
      });
  }

  useEffect(() => {

    // load office info
    mongoUser
      .mongoClient(mongoConfig.realmServiceName)
      .db(mongoConfig.databaseName)
      .collection('offices')
      .findOne({_id: selectedOfficeID}, { _id: 1, name: 1, company: 1 })
      .then((foundOffice) => {
        setFoundOfficeInfo(foundOffice);
      }).catch((err) => {
        setFoundOfficeInfo(null);
        console.log('Location Loading error: ' + err);
      });

    // load office notes
    mongoUser
      .mongoClient(mongoConfig.realmServiceName)
      .db(mongoConfig.databaseName)
      .collection('notes')
      .find(
        {officeID: selectedOfficeID},
        {
          sort: { utcDateTime: -1 },
        }
      )
      .then((foundNotes) => {
        setOfficeNotes(foundNotes);
      }).catch((err) => {
        setOfficeNotes(undefined);
        console.log('Location Loading error: ' + err);
      });

    // load notes related to the office
    // mongoUser
    //   .mongoClient(mongoConfig.realmServiceName)
    //   .db(mongoConfig.databaseName)
    //   .collection('usersdata')
    //   .find(
    //       {role: 'officeadmin', userID: {$exists: true}, officesAccess: { $in: [selectedOfficeID]} },
    //       {userID: 1 , displayName: 1, company: 1, }
    //     )
    //   .then((foundManagers) => {
    //     setOfficeManagersRelated(
    //       foundManagers.map((singleManager) => {
    //         return singleManager.userID;
    //       })
    //     );
    //   }).catch((err) => {
    //     setOfficeManagersRelated([]);
    //     console.log('Location Manager loading error: ' + err);
    //   });

    // load office notes
    setOfficeNotes([]);
  }, [selectedOfficeID]);

  return (
    <Card className={classes.notesCard}>
      {
        // OFFICE NOT SELECTED DISPLAY LIST OF OFFICES
      }

      { ( !selectedOfficeID ) &&
        <CardHeader
        avatar={ <EditIcon /> }
        action={
          <IconButton onClick={() => {setSelectedOfficeID(null); setFoundOfficeInfo(null); closeChat();}} aria-label="close">
            <CloseIcon />
          </IconButton>
        }
        title="Location Notes"
      />
      }

      { ( !selectedOfficeID && officesToDisplay.length === 0) &&
        <CardContent>
          <Skeleton variant="rounded" height={48} sx={{marginBottom: '0.5em'}}/>
          <Skeleton variant="rounded" height={48} sx={{marginBottom: '0.5em'}} />
          <Skeleton variant="rounded" height={48} sx={{marginBottom: '0.5em'}} />
          <Skeleton variant="rounded" height={48} sx={{marginBottom: '0.5em'}} />
          <Skeleton variant="rounded" height={48} sx={{marginBottom: '0.5em'}} />
          <Skeleton variant="rounded" height={48} sx={{marginBottom: '0.5em'}} />
          <Skeleton variant="rounded" height={48} sx={{marginBottom: '0.5em'}} />
        </CardContent>
      }

      { ( selectedOfficeID === null && officesToDisplay.length >= 0) &&
        <CardContent>
          <List className={classes.officesList}>
            { officesToDisplay.map((oneOffice) => (
                <ListItem key={oneOffice.id} disablePadding>
                  <ListItemButton onClick={ () => {setSelectedOfficeID(oneOffice.id)} } selected={( filterSelectedOfficeID && oneOffice.id === filterSelectedOfficeID ) ? true : false }>
                    <ListItemText primary={oneOffice.label} />
                  </ListItemButton>
                </ListItem>
              ))
            }
          </List>
        </CardContent>
      }



      {
        // ==============================
        // OFFICE IS SELECTED DISPLAY NOTES
      }

      { ( foundOfficeInfo && foundOfficeInfo.name ) &&
        <CardHeader
        avatar={
          <IconButton onClick={() => {setSelectedOfficeID(null); setFoundOfficeInfo(null);}} aria-label="back">
            <ArrowBackIcon />
          </IconButton>
        }
        action={
          <IconButton onClick={() => {setSelectedOfficeID(null); closeChat();}} aria-label="close">
            <CloseIcon />
          </IconButton>
        }
        title={foundOfficeInfo.name}
      />
      }

      { ( foundOfficeInfo && officeNotes === undefined) &&
        <CardContent>
          <Skeleton variant="rounded" height={120} sx={{marginBottom: '0.5em'}}/>
          <Skeleton variant="rounded" height={64} sx={{marginBottom: '0.5em'}}/>
          <Skeleton variant="rounded" height={48} sx={{marginBottom: '0.5em'}} />
          <Skeleton variant="rounded" height={86} sx={{marginBottom: '0.5em'}} />
        </CardContent>
      }

      { ( foundOfficeInfo && officeNotes.length > 0 ) &&
        <CardContent>
          <List className={classes.notesList}>
            { officeNotes.map((oneNote) => (
                <ListItem key={oneNote._id} disablePadding>
                  <ListItemText primary={oneNote.note} secondary={moment(oneNote.utcDateTime).tz(userTimeZone).format('MMM D Y, h:mm A')+' by '+oneNote.byUserName} />
                </ListItem>
              ))
            }
          </List>
        </CardContent>
      }

      { ( foundOfficeInfo && officeNotes.length === 0) &&
        <CardContent>
          <Typography variant="caption">No notes for this office ...</Typography>
        </CardContent>
      }

      { ( foundOfficeInfo ) &&
        <CardActions className={classes.notesActions}>
          <TextareaAutosize
            placeholder="Type your note here..."
            value={newNoteText}
            onChange={(e) => {
              setNewNoteText(e.target.value);
            }}/>
          <IconButton disabled = { (foundOfficeInfo && !saveInProgress) ? false : true }color="success" sx={{marginLeft: '0.5em'}} onClick={() => {handleSendNoteBtn()}} aria-label="send">
            <SendIcon />
          </IconButton>
        </CardActions>
      }
    </Card>
  );
}

export default AdminNotesCard;
