import React, { Suspense } from 'react';
import { useParams } from 'react-router-dom';
import { Box, LinearProgress } from '@mui/material';
import { useSelector } from "react-redux";
import makeStyles from '@mui/styles/makeStyles';

import { pagesConstants as pc } from '../constants';

import SideBar from '../components/Sidebar/SideBar';

//pages included into main build bundle
const DashboardPage = React.lazy(() => import('./DashboardPage'));
import ErrorBoundary from '../components/Common/ErrorBoundary'; // Import the ErrorBoundary component
import { NotFoundPage } from './NotFoundPage';
import { AccessDeniedPage } from './AccessDeniedPage';
import { LoginPage } from './LoginPage';
import { ConfirmEmailPage } from './ConfirmEmailPage';
import { EmailedInvitationPage } from './EmailedInvitationPage';
import { GoogleAuthPage } from './GoogleAuthPage';
import { AzureAuthPage } from './AzureAuthPage';
import { InRefreshPage } from './InRefreshPage';

import { AnsweredCallsPage } from './Calls/AnsweredCallsPage';
import { AllCallsPage } from './Calls/AllCallsPage';
import { BookedCallsPage } from './Calls/BookedCallsPage';
import { NotBookedCallsPage } from './Calls/NotBookedCallsPage';
import { MissedCallsPage } from './Calls/MissedCallsPage';
import { BusyCallsPage } from './Calls/BusyCallsPage';
import { NoAnswerCallsPage } from './Calls/NoAnswerCallsPage';
import { IsSpamCallsPage } from './Calls/IsSpamCallsPage';
import { HangUpCallsPage } from './Calls/HangUpCallsPage';
import { VoicemailCallsPage } from './Calls/VoicemailCallsPage';
import { UnknownCallsPage } from './Calls/UnknownCallsPage';
import { OtherCallsPage } from './Calls/OtherCallsPage';

const TaggedCallsPage = React.lazy(() => import('./Calls/TaggedCallsPage'));
const ReviewCallPage = React.lazy(() => import('./Calls/ReviewCallPage'));

import { SettingsPage } from './Account/SettingsPage';

// lazy loaded Alerts pages
const AllDataPage = React.lazy(() => import('./Alerts/AllDataPage'));
// const CallVolumePage = React.lazy(() => import('./Alerts/CallVolumePage'));
// const ApptCallsPage = React.lazy(() => import('./Alerts/ApptCallsPage'));
// const ApptCallsDropPage = React.lazy(() => import('./Alerts/ApptCallsDropPage'));
// const AlertsMissedPage = React.lazy(() => import('./Alerts/AlertsMissedPage'));
// const AlertsOtherPage = React.lazy(() => import('./Alerts/AlertsOtherPage'));
// const AlertsConversionsPage = React.lazy(() => import('./Alerts/AlertsConversionsPage'));


// lazy loaded Manage pages
const UsersPage = React.lazy(() => import('./Manage/UsersPage'));
const GroupsPage = React.lazy(() => import('./Manage/GroupsPage'));
const OfficesPage = React.lazy(() => import('./Manage/OfficesPage'));
const CallViewsPage = React.lazy(() => import('./Manage/CallViewsPage'));

const Pages = {
  [pc.PAGE_DASHBOARD] : DashboardPage,
  [pc.PAGE_NOT_FOUND] : NotFoundPage,
  [pc.PAGE_ACCESS_DENIED] : AccessDeniedPage,
  [pc.PAGE_LOGIN] : LoginPage,
  [pc.PAGE_GOOGLE_AUTH] : GoogleAuthPage,
  [pc.PAGE_CONFIRM_EMAIL] : ConfirmEmailPage,
  [pc.PAGE_EMAILED_INVITATION] : EmailedInvitationPage,
  [pc.PAGE_AZURE_AUTH] : AzureAuthPage,
  [pc.PAGE_INREFRESH] : InRefreshPage,
  [pc.PAGE_CALLS_ALL] : AllCallsPage,
  [pc.PAGE_CALLS_ANSWERED] : AnsweredCallsPage,
  [pc.PAGE_CALLS_BOOKED] : BookedCallsPage,
  [pc.PAGE_CALLS_NOTBOOKED] : NotBookedCallsPage,
  [pc.PAGE_CALLS_MISSED] : MissedCallsPage,
  [pc.PAGE_CALLS_BUSY] : BusyCallsPage,
  [pc.PAGE_CALLS_NOANSWER] : NoAnswerCallsPage,
  [pc.PAGE_CALLS_ISSPAM] : IsSpamCallsPage,
  [pc.PAGE_CALLS_HANGUP] : HangUpCallsPage,
  [pc.PAGE_CALLS_VOICEMAIL] : VoicemailCallsPage,
  [pc.PAGE_CALLS_UNKNOWN] : UnknownCallsPage,
  [pc.PAGE_CALLS_OTHER] : OtherCallsPage,
  [pc.PAGE_CALLS_TAGGED] : TaggedCallsPage,
  [pc.PAGE_REVIEWCALL] : ReviewCallPage,
  [pc.PAGE_GROUPS] : GroupsPage,
  [pc.PAGE_USERS] : UsersPage,
  [pc.PAGE_OFFICES] : OfficesPage,
  [pc.PAGE_CALLVIEWS] : CallViewsPage,
  [pc.PAGE_ALERTSALLDATA] : AllDataPage,
  // [pc.PAGE_ALERTSVOLUME] : CallVolumePage,
  // [pc.PAGE_APPTCALLSDROP] : ApptCallsDropPage,
  // [pc.PAGE_APPTCALLS] : ApptCallsPage,
  // [pc.PAGE_ALERTSMISSED] : AlertsMissedPage,
  // [pc.PAGE_ALERTSOTHER] : AlertsOtherPage,
  // [pc.PAGE_ALERTSCONVERSIONS] : AlertsConversionsPage,
  [pc.PAGE_ACCOUNTSETTINGS] : SettingsPage,
}

const useStyles = makeStyles((theme) => ({
  rootWrap: {
    display: 'flex',
    height: '100vh',
    "@media print": {
      height: '100%',
    },
  },
  contentWrap: {
    flexGrow: 1,
    maxHeight: '100%',
    overflow: 'hidden',
    overflowY: 'scroll',
    backgroundColor: theme.palette.background.default,
    '& .pageContent': {
      position: 'relative',

      height: '100%',
      padding: theme.spacing(3),
      paddingTop: '4em',

      "@media print": {
      },
    },

  },
  mainWrap: {
    height: '100%',
  },
  progressBar: {
    width: '100%',
    position: 'fixed',
    zIndex: theme.zIndex.drawer + 2,

  },

}));

const PageRoot = (props) => {
  const classes = useStyles();
  const params = useParams();

  const authProvider = useSelector( (state) => (state.auth && state.auth.user && state.auth.user.providerType) ? state.auth.user.providerType : null );

  const isAuthenticated = useSelector( (state) => state.auth.isAuthenticated );
  const authInProgress = useSelector( (state) => state.auth.inProgress );
  const authInRefresh = useSelector( (state) => state.auth.inRefresh );
  const somethingIsLoading = useSelector( (state) => state.app.somethingIsLoading );

// console.log(props);
// console.log(params);
  let renderDashboardLayout = (isAuthenticated && authProvider !== 'anon-user' && !authInRefresh && !authInProgress) ? true : false;
  let SelectedPageComponent = Pages[props.page];

  return (
    <div className={classes.rootWrap}>
      { renderDashboardLayout
        ?
          <Box className={classes.mainWrap} display="flex" flexGrow={1} flexDirection="row">
            { somethingIsLoading &&
              <LinearProgress className={classes.progressBar} /> }
            <SideBar  />
            <Box className={classes.contentWrap}>
            <Box className="pageContent">
              <ErrorBoundary>
                <Suspense fallback={<InRefreshPage />}>
                  <SelectedPageComponent className="pageContent" {...props} {...params} />
                </Suspense>
              </ErrorBoundary>
              </Box>
            </Box>
          </Box>
        : <SelectedPageComponent {...props} {...params} />
      }
    </div>
  );

};

export default PageRoot;
