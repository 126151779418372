const theApp = {
  ver: '6.0.0',
  roles: {
    'admin': 'Ring Staff',
    'companyadmin': 'Company Admin',
    'groupadmin': 'Group Admin',
    'officeadmin': 'Location Admin',
    'callreviewer': 'Reviewer',
  },
  companies: [
    { 'code': 'tso', name: 'TSO', logo: '' },
    { 'code': 'eyecarepro', name: 'Ring Analytics', logo: '' },
    { 'code': 'williams', name: 'Williams Group', logo: '' },
    { 'code': 'myeyedr', name: 'MyEyeDr', logo: '' },
    { 'code': 'vortala', name: 'Vortala', logo: '' },
    { 'code': 'aeg', name: 'AEG', logo: '' },
    { 'code': 'vsp', name: 'VSP', logo: '' },
    { 'code': 'nsg', name: 'NSG', logo: '' },
    { 'code': 'keplr', name: 'Keplr', logo: '' },
    { 'code': 'provision', name: 'Provision', logo: '' },
    { 'code': 'refocus', name: 'Refocus', logo: '' },
    { 'code': 'alcon', name: 'Alcon', logo: '' },
  ],
};

export default theApp;
